const ACTION_NAME = [
  { value: 1, text: 'ОТМЕНА' },
  { value: 2, text: 'ОПЛАЧЕН' },
  { value: 3, text: 'ЗАРЕЗЕРВИРОВАН' },
  { value: 4, text: 'ПРОВЕРЕН' },
  { value: 5, text: 'ПОДТВЕРЖДЕН' },
  { value: 6, text: 'ВОЗРАЩЕН' },
  { value: 7, text: 'ЗАВЕРШЕН' }
];
const ESCORT_TYPES = {
  1: 'Сопровождение',
  2: 'Портье'
};
const NEXT_STATE_NAME = [
  { value: 1, text: 'Заявка создана, ожидается оплата' },
  { value: 2, text: 'Оплата не выполнена' },
  { value: 3, text: 'Оплата выполнена, ожидается обработка оператором' },
  { value: 4, text: 'Заявка проверена, рабочий назначен' },
  { value: 5, text: 'Взято в работу назначенным рабочим' },
  { value: 6, text: 'Рабочий отменил заявку' },
  { value: 7, text: 'Заявка завершена' },
  { value: 100, text: 'Заявка отменена' }
];
const PAYMENT_TYPE = [
  { value: 1, text: 'Наличные' },
  { value: 2, text: 'Карта' }
];
const TARIFF_TYPE = [
  { value: 1, text: 'Для 1 вокзала' },
  { value: 2, text: 'Для 2-х вокзалов' }
];
const LUGGAGE_LIST = [
  { value: 1, text: '1 место' },
  { value: 2, text: '2 места' },
  { value: 3, text: '3 места' },
  { value: 4, text: '4 места' },
  { value: 5, text: '5 мест' }
];
const DATE_TO_LIST = [
  { value: '1', text: '15 минут' },
  { value: '2', text: '30 минут' },
  { value: '3', text: '45 минут' },
  { value: '4', text: '1 час' }
];
export default {
  getActionTypeByValue,
  getNextStateByValue,
  getTariffType,
  getPaymentType,
  getLuggageName,
  getEscortTypeName,
  getOrderStatus
};
function getEscortTypeName(escortType) {
  return ESCORT_TYPES[escortType] || 'Тип: ' + escortType;
}
function getActionTypeByValue(value) {
  return getTextByValue(ACTION_NAME, value);
}
function getNextStateByValue(value) {
  return getTextByValue(NEXT_STATE_NAME, value);
}
function getTariffType(value) {
  return getTextByValue(TARIFF_TYPE, value);
}
function getPaymentType(value) {
  return getTextByValue(PAYMENT_TYPE, value);
}
function getLuggageName(value) {
  return getTextByValue(LUGGAGE_LIST, value);
}
function getOrderStatus(value) {
  return NEXT_STATE_NAME[value] || 'Код ' + value;
}
function getTextByValue(list, value) {
  let name = value;
  for (let i = 0; i < list.length; i++) {
    if (list[i].value === value) {
      name = list[i].text;
      break;
    }
  }
  return name;
}
